<template>
    <v-container>
        <v-card slot="rightContainer" outlined>
            <v-card-title class="grey lighten-4">
                <b>HISTORICO ORDENES DE EQUIPAMIENTO</b>
                <v-spacer />
                <v-col cols="12" sm="3">
                    <v-menu ref="menu" v-model="menu3" :close-on-content-click="false" transition="scale-transition"
                        offset-y max-width="290px" min-width="auto">
                        <template #activator="{ on, attrs }">
                            <v-text-field v-model="fechaFiltro" hide-details="auto" dense label="FILTRAR POR RANGO - MES"
                                prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
                        </template>
                        <v-date-picker v-model="fechaFiltro" range no-title scrollable>
                            <v-spacer />
                            <v-btn text color="primary" @click="menu3 = false"> CERRAR </v-btn>
                            <v-btn color="primary" :loading="loadingLista" @click="getLista"> BUSCAR </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-text-field dense v-model="search" append-icon="mdi-magnify" label="BUSCAR" single-line
                        hide-details />
                </v-col>
            </v-card-title>
            <v-card-text>
                <v-row dense>
                    <v-col cols="12">
                        <v-data-table multi-sort :search="search" :items="listaOEqui" :headers="headers" class="elevation-1"
                            :items-per-page="20">
                            <template v-slot:[`item.action`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="orange lighten-2" small
                                            @click="calltoprint(item)">mdi-factory</v-icon>
                                    </template>
                                    <span>Descargar</span>

                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="red" small
                                            @click="closePosition(item)">mdi-clock-in</v-icon>
                                    </template>
                                    <span>Cerrar Orden</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                                <v-chip dark>
                                    {{ item.status }}
                                </v-chip>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { format, subDays, addDays, parseJSON } from 'date-fns'

export default {
    data() {
        return {
            fechaFiltro: [format(subDays(new Date(), 31), 'yyyy-MM-dd'), format(addDays(new Date(), 1), 'yyyy-MM-dd')],
            menu3: false,
            loadingLista: false,
            dataProducto: '',
            search: '',
            headers: [
                { text: '', value: 'action', sortable: false },
                { text: 'ID', value: 'id' },
                { text: 'status', value: 'statusName' },

                { text: 'fechaEmision', value: 'fechaEmision' },
                { text: 'vendedor', value: 'vendedor' },
                { text: 'personalCargo', value: 'personalCargo' },

                { text: 'ordenPedido', value: 'ordenPedido' },
                { text: 'producto', value: 'producto' },
                { text: 'color', value: 'color' },
                { text: 'medida', value: 'medida' },

                { text: 'material', value: 'material' },
                { text: 'observaciones', value: 'observaciones' }
            ]
        }
    },
    computed: {
        ...mapGetters('inventario', ['ordenEquipamiento']),
        listaOEqui() {
            let newLista = []
            if (this.ordenEquipamiento.length > 0) {
                this.ordenEquipamiento.map((x) => {
                    newLista.push({
                        ...x,
                        items: x.items ? JSON.parse(x.items) : [],
                        statusName: x.status == 1? 'ACTIVE': "CERRADO"
                    })
                })
            }
            // console.log('newLista', newLista)

            return newLista
        }
    },
    methods: {
        async getLista() {
            this.loadingLista = true
            await this.$store.dispatch('inventario/getListaOrdenEquipamiento', { fechaFiltro: this.fechaFiltro })

            this.loadingLista = false
            this.menu3 = false
        },

        async calltoprint(item) {
            var today = new Date()
            var dd = today.getDate()
            var mm = today.getMonth() + 1 //January is 0!
            var yyyy = today.getFullYear()

            if (dd < 10) {
                dd = '0' + dd
            }

            if (mm < 10) {
                mm = '0' + mm
            }

            today = yyyy + '-' + mm + '-' + dd
            console.log('item', item)
            //console.log('thisform', this.form)
            //console.log('this.dataProducto', this.dataProducto)
            const dataOrdenEquipamiento = await this.$store.dispatch('ventas/traerOrdenesEquipamiento', { id: item.id })
            console.log('dataOrdenProduccion', dataOrdenEquipamiento)
            await this.$store.dispatch('reporte/reporteOrdenEquipamiento', {
                orden: item.id,
                vendedor: dataOrdenEquipamiento[0].vendedor,
                fecha: today,
                ordenpedido: dataOrdenEquipamiento[0].ordenPedido,
                personacargo: dataOrdenEquipamiento[0].personalCargo,
                producto: dataOrdenEquipamiento[0].producto,
                model: dataOrdenEquipamiento[0].model,
                medida: dataOrdenEquipamiento[0].medida,
                color: dataOrdenEquipamiento[0].color,
                posicion: dataOrdenEquipamiento[0].posicion,
                observacion: dataOrdenEquipamiento[0].observaciones,
                items: item.items
            })
        },
        async closePosition(item) {
            console.log('itemAQUIIII--------------------', item)
            await this.$store.dispatch('inventario/closeOrdenEquipamiento', {
                data: {
                    status: !item.status
                },
                id: item.id
            })
            await this.getLista()

        }
    },
    async mounted() {
        await this.getLista()
        const obtenerProducto = await this.$store.dispatch('ventas/obtenerProducto', {
            id: this.$route.query.idProducto
        })
        this.dataProducto = obtenerProducto
    }
}
</script>
